import {
	CollectionDisplayCard,
	CollectionGridCard,
	CollectionListCard,
	CollectionPhotoCard,
	CollectionPillCard,
	CollectionPreviewCard,
	CollectionSelectCard,
	CollectionSubmitCard,
} from 'components/collection/collection-card';
import { ListWrapper } from 'molecules/lists';

const CollectionList = ({
	type = 'list',
	icon = 'layer-group',
	title = 'collections',
	text = '',
	collections,
	...listProps
}) => {
	const props = { type, icon, title, text, ...listProps };

	const renderItem = props => {
		switch (type) {
			case 'display':
				return <CollectionDisplayCard collection={props.item} {...props} />;

			case 'grid':
				return <CollectionGridCard collection={props.item} {...props} />;

			case 'list':
				return <CollectionListCard collection={props.item} {...props} />;

			case 'photo':
				return <CollectionPhotoCard collection={props.item} {...props} />;

			case 'pill':
				return <CollectionPillCard collection={props.item} {...props} />;

			case 'preview':
				return <CollectionPreviewCard collection={props.item} {...props} />;

			case 'select':
				return <CollectionSelectCard collection={props.item} {...props} />;

			case 'submit':
				return <CollectionSubmitCard collection={props.item} {...props} />;
		}
	};

	// Render
	return <ListWrapper data={collections} renderItem={renderItem} {...props} />;
};

export { CollectionList };
