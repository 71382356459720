import { Fragment } from 'react';

import { BorderBtn } from '@playbooks/interface/buttons';
import { Span } from '@playbooks/interface/html';
import { BorderLink } from '@playbooks/interface/links';
import { OwnerAvatar } from 'components/owner/owner-avatar';
import { useAccount } from 'contexts';

export const CollectionCardActions = ({ type, collection, taskRunning, onClick, rootLink }) => {
	const account = useAccount();
	const isOwner = account.account?.id === collection.ownerId && account.type === collection.ownerType;
	const props = { collection, isOwner, taskRunning, onClick, rootLink };

	switch (type) {
		case 'grid':
			return <CollectionGridCardActions {...props} />;

		case 'list':
			return <CollectionListCardActions {...props} />;

		case 'submit':
			return <CollectionSubmitCardActions {...props} />;
	}
};

export const CollectionGridCardActions = ({ collection, isOwner, rootLink }) => (
	<Fragment>
		<OwnerAvatar type='card' variant={collection.ownerType} owner={collection.owner} />
		<Span display='flex-end' space='space-x-4'>
			{/* {isOwner && <BorderLink size='icon' icon='cog' href={`${rootLink}/collections/${collection.uuid}`} />} */}
		</Span>
	</Fragment>
);

export const CollectionListCardActions = ({ collection, isOwner, rootLink }) => (
	<Fragment>
		{/* {isOwner && <BorderLink size='icon' icon='cog' href={`${rootLink}/collections/${collection.uuid}`} />} */}
	</Fragment>
);

export const CollectionSubmitCardActions = ({ collection, taskRunning, onClick }) => (
	<Fragment>
		<BorderBtn
			size='icon'
			icon='trash'
			taskRunning={taskRunning.id === collection.id && taskRunning.task === 'delete'}
			onClick={() => onClick(collection)}
		/>
		<BorderLink size='icon' icon='pencil' href={`/submit/collection/${collection.uuid}`} />
		{collection.status !== 'draft' && <BorderLink size='icon' icon='eye' href={`/collections/${collection.uuid}`} />}
	</Fragment>
);
