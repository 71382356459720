import { Fragment } from 'react';

import { CardHeader, CardImg } from '@playbooks/interface/cards';
import { Col, Grid } from '@playbooks/interface/grid';

export const CollectionCardHeader = ({ type = 'grid', collection = {}, tailwind = {} }) => {
	const items = [...(collection.repos || []), ...(collection.bounties || [])];
	const props = { collection, items, tailwind };
	// Render
	switch (type) {
		case 'display':
			return <CollectionDisplayCardHeader {...props} />;
		case 'grid':
			return <CollectionGridCardHeader {...props} />;
		case 'list':
			return <CollectionListCardHeader {...props} />;
	}
};

export const CollectionDisplayCardHeader = ({ collection, items, tailwind }) => (
	<Fragment>
		{collection.thumbnail ? (
			<CardHeader
				aspect='aspect-[1/1]'
				borderRadius='rounded-md'
				display='flex-middle'
				overflow='overflow-hidden'
				spacing='m-4 p-2'
				width='w-20'
				{...tailwind?.header}>
				<CardImg src={collection.thumbnail} borderRadius='rounded-md' />
			</CardHeader>
		) : (
			<CardHeader
				aspect='aspect-[1/1]'
				borderRadius='rounded-md'
				overflow='overflow-hidden'
				spacing='m-4 p-2'
				width='w-20'
				{...tailwind?.header}>
				<Grid gap='' spacing='' {...tailwind?.grid}>
					<Col span='6'>
						<CardImg src={items[0]?.thumbnail || ''} />
					</Col>
					<Col span='6'>
						<CardImg src={items[1]?.thumbnail || ''} />
					</Col>
					<Col span='6'>
						<CardImg src={items[2]?.thumbnail || ''} />
					</Col>
					<Col span='6'>
						<CardImg src={items[3]?.thumbnail || ''} />
					</Col>
				</Grid>
			</CardHeader>
		)}
	</Fragment>
);

export const CollectionGridCardHeader = ({ collection, items, tailwind }) => (
	<Fragment>
		{collection.thumbnail ? (
			<CardHeader bgColor='' spacing='p-4' width='w-full'>
				<CardImg src={collection.thumbnail} borderRadius='rounded-md' />
			</CardHeader>
		) : (
			<CardHeader
				aspect='aspect-[1/1]'
				borderRadius='rounded-md'
				overflow='overflow-hidden'
				spacing='m-4'
				{...tailwind?.header}>
				<Grid gap='' spacing='' {...tailwind?.grid}>
					<Col span='6'>
						<CardImg src={items[0]?.thumbnail || ''} />
					</Col>
					<Col span='6'>
						<CardImg src={items[1]?.thumbnail || ''} />
					</Col>
					<Col span='6'>
						<CardImg src={items[2]?.thumbnail || ''} />
					</Col>
					<Col span='6'>
						<CardImg src={items[3]?.thumbnail || ''} />
					</Col>
				</Grid>
			</CardHeader>
		)}
	</Fragment>
);

export const CollectionListCardHeader = ({ collection, items, tailwind }) => (
	<Fragment>
		{collection.thumbnail ? (
			<CardHeader bgColor='' spacing='' width='w-20 lg:w-24'>
				<CardImg src={collection.thumbnail} borderRadius='rounded-md' />
			</CardHeader>
		) : (
			<CardHeader
				aspect='aspect-[1/1]'
				borderRadius='rounded-md'
				overflow='overflow-hidden'
				spacing=''
				width='w-20 lg:w-24'
				{...tailwind?.header}>
				<Grid gap='' spacing='' {...tailwind?.grid}>
					<Col span='6'>
						<CardImg src={items[0]?.thumbnail || ''} />
					</Col>
					<Col span='6'>
						<CardImg src={items[1]?.thumbnail || ''} />
					</Col>
					<Col span='6'>
						<CardImg src={items[2]?.thumbnail || ''} />
					</Col>
					<Col span='6'>
						<CardImg src={items[3]?.thumbnail || ''} />
					</Col>
				</Grid>
			</CardHeader>
		)}
	</Fragment>
);
