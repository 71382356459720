import { Fragment } from 'react';

import { Badge } from '@playbooks/interface/badges';
import { FadIcon } from '@playbooks/interface/icons';

const CollectionBadges = ({ collection, ...props }) => {
	return (
		<Fragment>
			{collection.featured && (
				<Badge {...props}>
					<FadIcon icon='badge-check' />
				</Badge>
			)}
			{collection.editorial && (
				<Badge {...props}>
					<FadIcon icon='crown' />
				</Badge>
			)}
		</Fragment>
	);
};

export { CollectionBadges };
